import React, { useEffect, useRef, useState } from "react";
import { createNoise3D } from "simplex-noise";

export default function Awoken() {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationRef = useRef<number | null>(null);
  const [fps, setFps] = useState(0);

  useEffect(() => {
    const noise3D = createNoise3D();
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    let width: number, height: number, imgdata: ImageData, data: Uint8ClampedArray;

    const resizeCanvas = () => {
      const dpr = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();
      canvas.width = width = rect.width * dpr;
      canvas.height = height = rect.height * dpr;
      canvas.style.width = `${rect.width}px`;
      canvas.style.height = `${rect.height}px`;
      ctx.scale(dpr, dpr);
      imgdata = ctx.createImageData(width, height);
      data = imgdata.data;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    let t = 0;
    const dna = [233, 269, 571, 771, 0.6, 49, 0.23, 0.83, 333, 775, 218, 39, 249];

    let lastTime = 0;
    let frameCount = 0;
    let lastFpsUpdate = 0;

    const animate = (currentTime: number) => {
      // FPS calculation
      frameCount++;
      if (currentTime - lastFpsUpdate > 1000) {
        setFps(Math.round(frameCount * 1000 / (currentTime - lastFpsUpdate)));
        frameCount = 0;
        lastFpsUpdate = currentTime;
      }

      const tOverDna8 = t / dna[8];
      const tOverDna9 = t / dna[9];

      for (let y = 0; y < height; y += 2) {
        const yOverDna1 = y / dna[1];
        const yOverDna3 = y / dna[3];
        for (let x = 0; x < width; x += 2) {
          const r = noise3D(x / dna[0], yOverDna1, tOverDna8) * dna[4] + dna[6];
          const g = noise3D(x / dna[2], yOverDna3, tOverDna9) * dna[5] + dna[7];

          const value = ((r || g) * dna[10]) | 0;
          const value2 = (r * g * dna[11]) | 0;

          const index = (x + y * width) * 4;
          data[index] = data[index + 4] = data[index + width * 4] = data[index + width * 4 + 4] = value;
          data[index + 1] = data[index + 5] = data[index + width * 4 + 1] = data[index + width * 4 + 5] = value2;
          data[index + 2] = data[index + 6] = data[index + width * 4 + 2] = data[index + width * 4 + 6] = dna[12];
          data[index + 3] = data[index + 7] = data[index + width * 4 + 3] = data[index + width * 4 + 7] = 255;
        }
      }
      t++;
      ctx.putImageData(imgdata, 0, 0);
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}